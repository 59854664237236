import { f7, Link, Navbar, NavLeft, NavTitle, Page, Input, Button, NavRight, Card, Checkbox } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import {
  searchingOptionState,
  tourScheduleState,
  totalChargeState,
  searchingOptionDepartureTimeSelector,
  searchingOptionReturnTimeSelector,
  searchingOptionDateSelector,
  onewayState,
} from '@atoms';
import DetailContainer from '@components/search/DetailContainer';
import moment from 'moment';
import { setMainViewHash } from '@js/utils';
import { useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import Calendar from '@components/search/Calendar';
import { Moment } from 'moment/moment';
import TimePicker from '@components/search/TimePicker';
import calcIcon from '@assets/images/calc.png';
import resetIcon from '@assets/images/reset.png';
import backIcon from '@assets/images/back_icon.png';
import SingleCalendar from '@components/search/SingleCalendar';

const SearchPage = ({ f7router, f7route }) => {
  const { back, toolbar } = f7route.query;
  const queryClient = useQueryClient();
  const [searchingOption, setSearchingOption] = useRecoilState(searchingOptionState);
  const { departureDate, returnDate } = useRecoilValue(searchingOptionDateSelector);
  const [tourSchedule, setTourSchedule] = useRecoilState(tourScheduleState);
  const [startDate, setStartDate] = useState<Moment | null | Date>(departureDate);
  const [endDate, setEndDate] = useState<Moment | null | Date>(returnDate);
  const [departureTime, setDepartureTime] = useRecoilState(searchingOptionDepartureTimeSelector);
  const [returnTime, setReturnTime] = useRecoilState(searchingOptionReturnTimeSelector);
  const setTotalCharge = useSetRecoilState(totalChargeState);
  const KakaoPlaceRef = useRef(new (window as any).kakao.maps.services.Places());
  const dayDiff = returnDate ? moment(returnDate).diff(moment(departureDate), 'days') + 1 : 0;
  const [oneway, setOneway] = useRecoilState(onewayState);

  const searchPlaces = async (keyword: string, callback: any) => {
    if (!keyword.replace(/^\s+|\s+$/g, '')) {
      return false;
    }
    return KakaoPlaceRef.current.keywordSearch(keyword, await callback);
  };

  const resetCalendar = () => {
    (document.querySelector('#departureTime_picker') as HTMLInputElement).value = '오전 10 : 00';
    if (!oneway) {
      (document.querySelector('#returnTime_picker') as HTMLInputElement).value = '오후 05 : 00';
    }

    setStartDate(moment());
    setEndDate(null);
    setDepartureTime('오전 10 : 00');
    setReturnTime('오후 05 : 00');
  };

  const setTourInforms = useCallback(
    (start, end) => {
      setMainViewHash('out');
      const set = {} as any;
      set.departureDate = start;
      set.returnDate = '';
      set.totalDistance = 0;

      if (start !== null) {
        set.returnDate = end;
        const days = [];
        const _dayDiff = end ? moment(end).diff(moment(start), 'days') + 1 : 0;
        [...Array(_dayDiff)].forEach((day, index) => {
          days.push({
            day: moment(start).add(index, 'days').format('YY년 MM월 D일'),
            idx: index,
          });
        });

        if (days.length === 0 && tourSchedule.length === 0) {
          setTourSchedule([{ day: moment(start).format('YY년 MM월 D일'), idx: 0 }]);
        } else if (days.length === 1) {
          days.push({ ...days[0], idx: 1 });
          setTourSchedule(days.map((elem) => ({ ...elem, stopOvers: [] }), []));
        } else {
          setTourSchedule(days.map((elem) => ({ ...elem, stopOvers: [] }), []));
        }
      }

      setSearchingOption((prev) => ({ ...prev, ...set }));
      queryClient.setQueryData([REACT_QUERY_KEYS.DRIVER], { pages: [], pageParams: [undefined] });
    },
    [startDate, endDate, tourSchedule],
  );

  const isInputInvalid = useCallback(() => {
    console.log({ tourSchedule });
    return !tourSchedule.every((sc) => sc.departure && sc.destination);
  }, [tourSchedule]);

  const afterAuthenticatedProcess = () => {
    if (window.localStorage.getItem('isProcessingWithUnAuthenticated')) {
      const driverId = window.localStorage.getItem('driverId');
      const peoplesCount = JSON.parse(window.localStorage.getItem('peoplesCount'));
      const searchingOptionValue = JSON.parse(window.localStorage.getItem('searchingOption'));
      setTotalCharge(JSON.parse(window.localStorage.getItem('totalCharge')));
      setTourSchedule(JSON.parse(window.localStorage.getItem('tourSchedule')));
      setSearchingOption({
        ...searchingOptionValue,
        departureDate: moment(searchingOptionValue.departureDate),
        returnDate: moment(searchingOptionValue.returnDate),
      });

      f7router.navigate(`/drivers/${driverId}?people=${Number(peoplesCount)}`, {
        history: true,
      });
    }
  };

  useEffect(() => {
    setStartDate(departureDate);
    setEndDate(returnDate);
  }, [searchingOption.departureDate, searchingOption.returnDate]);

  const getDayList = useCallback(() => {
    const days = [];
    if (dayDiff >= 3) {
      for (let i = 1; i < dayDiff - 1; i += 1) {
        days.push({ day: moment(departureDate).add(i, 'days').format('YY년 MM월 D일'), idx: i });
      }
    }

    return days;
  }, [dayDiff, departureDate]);

  const checkOneway = () => {
    setOneway(true);
    if (tourSchedule.length > 1) {
      setTourSchedule([tourSchedule[0]]);
    }
  };

  return (
    <Page noToolbar={toolbar === 'hide'} name="search" className="" onPageInit={() => afterAuthenticatedProcess()}>
      <Navbar>
        <NavLeft>
          {back ? (
            <Link iconOnly slot="left" back>
              <img src={backIcon} alt="" width="18px" />
            </Link>
          ) : (
            <Link icon="menu_bars" panelOpen="left" />
          )}
        </NavLeft>
        <NavTitle>버스정보 검색</NavTitle>
        <NavRight>
          <a href="" onClick={resetCalendar}>
            <img src={resetIcon} alt="" width={20} />
          </a>
        </NavRight>
      </Navbar>

      {oneway ? (
        <SingleCalendar
          handler={setTourInforms}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      ) : (
        <Calendar
          handler={setTourInforms}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}

      <div>
        <div className="my-8 mx-4">
          <h1 className="text-xl font-bold">여정</h1>
          <div className="flex items-center my-4">
            <div className="text-sm text-gray-900">
              <Checkbox checked={oneway} onChange={() => checkOneway()} />
              <span onClick={() => checkOneway()} className="ml-1 text-gray-700 ">
                편도
              </span>
            </div>
            <div className="text-sm text-gray-900 ml-4">
              <Checkbox checked={!oneway} onChange={() => setOneway(false)} />
              <span onClick={() => setOneway(false)} className="ml-1 text-gray-700 ">
                왕복
              </span>
            </div>
          </div>
        </div>

        <div>
          <h1 className="text-xl font-bold m-4">가는 날</h1>
          <div className="row">
            <div className="col pl-4">
              <div className={`search-input ${!startDate && 'disabled'}`}>
                <img src={calcIcon} alt="" width="18px" className="inline-block mx-2" />
                {startDate ? moment(startDate)?.format('YYYY년 M월 DD일') : '날짜 선택'}
              </div>
            </div>

            <div className="col pr-4">
              <TimePicker el="departureTime" setter={setDepartureTime} timeValue={departureTime} />
            </div>
          </div>

          <DetailContainer
            index={0}
            searchPlaces={searchPlaces}
            lastIndex={dayDiff - 1 || 1}
            disabled={!(returnDate || oneway)}
            oneway={oneway}
          />
        </div>
        {!oneway && (
          <>
            <div>
              {getDayList().map((elem, index) => (
                <DetailContainer
                  key={`detail-${elem.day}-${elem.idx}`}
                  elem={elem}
                  searchPlaces={searchPlaces}
                  day={elem.day}
                  index={index + 1}
                  lastIndex={dayDiff - 1 || 1}
                  disabled={false}
                />
              ))}
            </div>

            <div>
              <h1 className="text-xl font-bold mx-4 mt-4 mb-4">오는 날</h1>
              <div className="row">
                <div className="col pl-4">
                  <div className={`search-input ${!endDate && 'disabled'}`}>
                    <img src={calcIcon} alt="" width="18px" className="inline-block mx-2" />
                    {endDate ? moment(endDate)?.format('YYYY년 M월 DD일') : '날짜 선택'}
                  </div>
                </div>
                <div className="col pr-4">
                  <TimePicker el="returnTime" setter={setReturnTime} timeValue={returnTime} />
                </div>
              </div>

              <DetailContainer
                index={dayDiff - 1 || 1}
                searchPlaces={searchPlaces}
                lastIndex={dayDiff - 1 || 1}
                disabled={!returnDate}
              />
            </div>
          </>
        )}
      </div>

      <div className="px-4 mt-8 mb-4">
        <button
          onClick={() => {
            if (oneway && searchingOption.returnDate === null) {
              setSearchingOption((prev) => ({ ...prev, returnDate: searchingOption.departureDate }));
            }

            f7router.navigate(`/search/confirm`);
          }}
          disabled={isInputInvalid()}
          className="button button-fill disabled:bg-[#b9c0c8] h-11 font-bold text-base"
        >
          다음
        </button>
      </div>
    </Page>
  );
};

export default React.memo(SearchPage);
