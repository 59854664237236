/* eslint-disable no-nested-ternary */
import { setMainViewHash } from '@js/utils';
import React, { useMemo, useCallback, useEffect } from 'react';
import { Block, f7, Link, List, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import { getReservations, readChatroomAPI } from '@api';
import useAuth from '@hooks/useAuth';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { REACT_QUERY_KEYS } from '@constants';
import { IoLockClosedOutline } from 'react-icons/io5';
import EmptyList from '@components/shared/EmptyList';
import { getNotificationSubscription, NotificationSubscription } from '@appsync';
import { createInAppNotification } from '@utils';
import NormalReservation from '@components/reservations/NormalReservation';

const NormalReservationIndexPage = ({ f7router }) => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const next = useCallback(
    async (subscription: NotificationSubscription) => {
      if (currentUser.isAuthenticated === false) return;
      const notification = subscription.value.data?.onNotificationCreateRecevier;
      if (!notification) return;

      const isCurrentUserReceiver = notification?.receiver_id === currentUser.uuid;
      const isInChatroom = notification.redirect_to === f7.views.current.router.currentRoute.path;

      if (isInChatroom) await readChatroomAPI(notification?.target_id || '');
      queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS);

      if (!isCurrentUserReceiver || isInChatroom) return;
      createInAppNotification(notification);
      queryClient.refetchQueries([REACT_QUERY_KEYS.NEW_MESSAGES, notification?.target_id]);
    },
    [currentUser, queryClient],
  );

  useEffect(() => {
    if (currentUser.isAuthenticated === false) return () => {};
    const subscription = getNotificationSubscription(currentUser.uuid).subscribe({ next });
    return () => subscription.unsubscribe();
  }, [currentUser, next]);

  const { data, isError, error, fetchNextPage, isLoading, refetch } = useInfiniteQuery(
    REACT_QUERY_KEYS.RESERVATION,
    // eslint-disable-next-line consistent-return
    async ({ pageParam: page = 1 }) => {
      if (currentUser.isAuthenticated) {
        const response = await getReservations(currentUser.email, page);
        return response || [];
      }
    },
    {
      getNextPageParam: (lastPage, pages) => (pages ? pages.length + 1 : 1),
    },
  );

  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 1,
  });

  const reservations = useMemo(() => data?.pages?.flat() || [], [data]);
  const reload = async (done = null) => {
    await refetch();
    if (done) done();
  };

  const loadMore = async () => {
    if (!isLoading && data.pages?.slice(-1)[0]?.length > 0) {
      await fetchNextPage();
    }
  };

  return (
    <Page
      name="NormalReservation"
      ptr
      ptrPreloader
      onPtrRefresh={reload}
      infinite
      infinitePreloader={isLoading}
      onInfinite={loadMore}
      onPageAfterIn={() => {
        setMainViewHash('in');
      }}
      onPageAfterOut={() => {
        setMainViewHash('out');
      }}
    >
      <Navbar>
        <NavLeft>
          <Link icon="menu_bars" panelOpen="left" />
        </NavLeft>
        <NavTitle>예약목록</NavTitle>
        <NavRight>
          <Link icon="noti_bell" href="/notifications" />
        </NavRight>
      </Navbar>
      <Block>
        {currentUser.isAuthenticated ? (
          isLoading ? (
            <div>로딩중입니다</div>
          ) : isError ? (
            <Block>{(error as any).message}</Block>
          ) : (
            <List className="mt-4">
              {reservations && reservations.length > 0 ? (
                <>
                  {reservations.map((reservation) => (
                    <NormalReservation
                      reservation={reservation}
                      refetch={refetch}
                      key={reservation?.id || ''}
                      f7router={f7router}
                    />
                  ))}
                </>
              ) : (
                <EmptyList title="예약 내역이 없습니다 :)" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
              )}
            </List>
          )
        ) : (
          <EmptyList title="배낭버스에 로그인 해주세요" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
        )}
      </Block>
    </Page>
  );
};

export default NormalReservationIndexPage;
