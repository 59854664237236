import { List, AccordionItem, AccordionContent, AccordionToggle } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import { PointDetail, StopOver } from '@interfaces';
import { useRecoilState } from 'recoil';
import { tourScheduleState } from '@atoms';
import { IoCloseCircle } from 'react-icons/io5';
import { isEmpty, map } from 'lodash';
import timeIcon from '@assets/images/time.png';
import stopoverBtn from '@assets/images/stopover_button.png';
import moment from 'moment/moment';
import calcIcon from '@assets/images/calc.png';

const DetailContainer = ({
  searchPlaces,
  index,
  lastIndex,
  day = undefined,
  oneway = false,
  disabled = true,
  elem = { idx: '' },
}) => {
  const [tourSchedule, setTourSchedule] = useRecoilState(tourScheduleState);
  const { day: previousDate } = tourSchedule[index - 1] || tourSchedule[lastIndex] || {};
  const { day: currentDate, departure, stopOvers, destination } = tourSchedule[index] || {};
  const { day: nextDate } = tourSchedule[index + 1] || {};

  let prevTwoDate;
  let nextTwoDate;

  if (tourSchedule.length > 2) {
    const { day: _prevDay } = tourSchedule[index - 2] || {};
    prevTwoDate = _prevDay;

    const { day: _day } = tourSchedule[index + 2] || {};
    nextTwoDate = _day;
  }

  const [pointList, setPointList] = useState({});
  const stopOverCount = useRef(1);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const maxStopOverLength = 5;
  let searchTarget: string;
  let stopOverId: number | string;

  const addStopOver = async (type: string) => {
    if (stopOvers && stopOvers.length < maxStopOverLength) {
      setTourSchedule(
        tourSchedule.map((schedule) =>
          `${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`
            ? {
                ...schedule,
                ...{ [`${type}`]: stopOvers.concat({ id: `${stopOverCount.current}`, region: '' }) },
              }
            : schedule,
        ),
      );
      stopOverCount.current += 1;
    }
  };

  const deleteStopOver = async (id: number | string, type: string) => {
    setTourSchedule(
      tourSchedule.map((schedule) =>
        `${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`
          ? {
              ...schedule,
              ...{
                [`${type}`]: stopOvers.filter((stopOver) => stopOver.id !== `${id}`),
              },
            }
          : schedule,
      ),
    );
    stopOverCount.current -= 1;
  };

  const placesSearchCallBack = async (data: string, status: any) => {
    setTimeout(() => {
      const cutData = data.slice(0, 5);
      // 시도 축약 데이터를 변환 => backend는 tmap, frontend는 kakao가 사용됨. 정확한 사유는 파악 불가
      const transData = map(cutData, (place: any) => {
        let transformData = {};
        switch (place.address_name.split(' ')[0]) {
          case '전남':
            transformData = {
              ...place,
              address_name: place.address_name.replace('전남', '전라남도'),
            };
            break;
          case '전북':
            transformData = {
              ...place,
              address_name: place.address_name.replace('전북', '전라북도'),
            };
            break;
          case '경기':
            transformData = {
              ...place,
              address_name: place.address_name.replace('경기', '경기도'),
            };
            break;
          case '서울':
            transformData = {
              ...place,
              address_name: place.address_name.replace('서울', '서울특별시'),
            };
            break;
          case '인천':
            transformData = {
              ...place,
              address_name: place.address_name.replace('인천', '인천광역시'),
            };
            break;
          case '강원':
            transformData = {
              ...place,
              address_name: place.address_name.replace('강원', '강원도'),
            };
            break;
          case '대전':
            transformData = {
              ...place,
              address_name: place.address_name.replace('대전', '대전광역시'),
            };
            break;
          case '대구':
            transformData = {
              ...place,
              address_name: place.address_name.replace('대구', '대구광역시'),
            };
            break;
          case '부산':
            transformData = {
              ...place,
              address_name: place.address_name.replace('부산', '부산광역시'),
            };
            break;
          case '충북':
            transformData = {
              ...place,
              address_name: place.address_name.replace('충북', '충청북도'),
            };
            break;
          case '충남':
            transformData = {
              ...place,
              address_name: place.address_name.replace('충남', '충청남도'),
            };
            break;
          case '경북':
            transformData = {
              ...place,
              address_name: place.address_name.replace('경북', '경상북도'),
            };
            break;
          case '경남':
            transformData = {
              ...place,
              address_name: place.address_name.replace('경남', '경상남도'),
            };
            break;
          case '광주':
            transformData = {
              ...place,
              address_name: place.address_name.replace('광주', '광주광역시'),
            };
            break;
          case '세종':
            transformData = {
              ...place,
              address_name: place.address_name.replace('세종', '세종특별자치시'),
            };
            break;
          case '울산':
            transformData = {
              ...place,
              address_name: place.address_name.replace('울산', '울산광역시'),
            };
            break;
          case '제주':
            transformData = {
              ...place,
              address_name: place.address_name.replace('제주', '제주특별자치도'),
            };
            break;
          default:
            transformData = place;
            break;
        }
        return transformData;
      });

      const kakaoCompleteStatus = 'OK';
      const isSearchTargetStopOver = searchTarget === 'stopOvers';
      if (status === kakaoCompleteStatus && isSearchTargetStopOver) {
        setPointList((prev) => ({ ...prev, [stopOverId]: transData }));
      } else if (status === kakaoCompleteStatus) {
        setPointList((prev) => ({ ...prev, [searchTarget]: transData }));
      } else {
        setPointList({});
      }
    }, 400);
  };

  const setScheduleByType = (type: string, value: string) => {
    switch (type) {
      case 'departure':
        setTourSchedule(
          tourSchedule.map((schedule) => {
            if (`${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`) {
              return {
                ...schedule,
                ...{
                  [`${type}`]: value,
                },
              };
            }
            if (!oneway && schedule.day === previousDate) {
              if (prevTwoDate) {
                return {
                  ...schedule,
                  ...{
                    [`departure`]: value,
                    [`destination`]: value,
                  },
                };
              }
              return {
                ...schedule,
                ...{
                  [`destination`]: value,
                },
              };
            }
            if (!oneway && schedule.day === prevTwoDate) {
              return {
                ...schedule,
                ...{
                  [`destination`]: value,
                },
              };
            }

            return schedule;
          }),
        );
        break;
      case 'destination':
        setTourSchedule(
          tourSchedule.map((schedule) => {
            if (`${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`) {
              return {
                ...schedule,
                ...{
                  [`${type}`]: value,
                },
              };
            }

            if (!oneway && schedule.day === nextDate) {
              if (nextTwoDate) {
                return {
                  ...schedule,
                  ...{
                    [`departure`]: value,
                    [`destination`]: value,
                  },
                };
              }
              return {
                ...schedule,
                ...{
                  [`departure`]: value,
                },
              };
            }

            if (!oneway && schedule.day === nextTwoDate) {
              return {
                ...schedule,
                ...{
                  [`departure`]: value,
                },
              };
            }

            return schedule;
          }),
        );
        break;
      default:
        break;
    }
  };

  const departureSelect = async (value: string, type: string, id = null) => {
    const isTypeStopOver = type === 'stopOvers';

    if (isTypeStopOver) {
      const duplicatedArr = JSON.parse(JSON.stringify(stopOvers));
      const mapped = duplicatedArr.map((item: StopOver) => {
        if (item.id === id) {
          item.region = value;
          return item;
        }
        return item;
      });

      setTourSchedule(
        tourSchedule.map((schedule) =>
          `${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`
            ? {
                ...schedule,
                ...{
                  [`${type}`]: mapped,
                },
              }
            : schedule,
        ),
      );
    }

    setScheduleByType(type, value);
    setPointList({});
  };

  const setPostCode = (value: string, type: string, id: string | null) => {
    const isTypeStopOver = type === 'stopOvers';

    if (isTypeStopOver) {
      const duplicatedArr = JSON.parse(JSON.stringify(stopOvers));
      const mapped = duplicatedArr.map((stopOver: StopOver) => {
        if (stopOver.id === `${id}`) {
          stopOver.region = value;
          return stopOver;
        }
        return stopOver;
      });
      setTourSchedule(
        tourSchedule.map((schedule) =>
          `${schedule.day}-${schedule.idx}` === `${currentDate}-${index}`
            ? {
                ...schedule,
                ...{
                  [`${type}`]: mapped,
                },
              }
            : schedule,
        ),
      );
    }

    stopOverId = id;
    setScheduleByType(type, value);
    searchTarget = type;

    if (!value) {
      setTimeout(() => setPointList({}), 300);
    } else {
      searchPlaces(value, placesSearchCallBack);
    }
  };

  const setPostCodeByType = (type: string, id) => {
    if (type === 'stopOvers') {
      return setPostCode('', 'stopOvers', `${id}`);
    }
    return setScheduleByType(`${type}`, '');
  };

  const searchResult = (type: string, id = null) => (
    <>
      {focused && pointList[id || type] && (
        <div className="z-50 absolute left-0 buttom-0 right-0 top-12 bg-white w-auto mx-4 rounded-lg border-background border-solid border">
          <div
            className="image-slide-delete-btn absolute top-2 right-4"
            onClick={() => {
              setPostCodeByType(type, id);
              setFocused(false);
            }}
          >
            <IoCloseCircle size="20px" />
          </div>
          {pointList[id || type].map((point: PointDetail) => {
            const selectedDeparture = `${point.address_name} ${point.place_name}`;
            return (
              <div className="my-3" key={point.id}>
                <a
                  className="font-medium pl-3"
                  onClick={() => {
                    departureSelect(selectedDeparture, type, id);
                    setFocused(true);
                  }}
                >
                  {point.place_name || point.road_address_name}
                  <div className="text-gray-500 text-sm pl-3">{point.road_address_name || point.address_name}</div>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </>
  );

  useEffect(() => {
    if (isEmpty(tourSchedule) && oneway) {
      setTourSchedule([{ day: moment(day).format('YY년 MM월 D일'), idx: 0 }]);
    }
  }, [tourSchedule, oneway, day]);

  return (
    <List accordionList noHairlinesMd style={{ zIndex: 'auto' }} className="my-2">
      {elem.idx && <h1 className="text-xl font-bold mx-4 mt-4 mb-4">{elem.idx + 1}일차</h1>}

      <div>
        {day && (
          <div className="row mt-4">
            <div className="col pl-4">
              <div className="search-input">
                <img src={calcIcon} alt="" width="18px" className="inline-block mx-2" />
                {day}
              </div>
            </div>
          </div>
        )}
        <div className="relative mt-2">
          <div className="flex px-4 mb-2">
            <input
              className={`pl-3 flex-1 search-input place-search overflow-ellipsis ${disabled && 'disabled'}`}
              value={departure || ''}
              placeholder="출발지를 검색해주세요"
              onChange={(e) => setPostCode(e.target.value, 'departure', null)}
              onFocus={onFocus}
              onKeyUp={onFocus}
            />
            <span
              className="input-clear-button mr-6"
              onClick={() => {
                setScheduleByType('departure', '');
                setFocused(false);
              }}
            />
          </div>
          {searchResult('departure')}
        </div>
        {stopOvers &&
          stopOvers.map((stopOver) => (
            <div className="relative" key={stopOver.id}>
              <div className="flex items-center px-4 py-2">
                <button
                  className="f7-icons text-xl text-secondary outline-none h-full"
                  onClick={() => deleteStopOver(stopOver.id, 'stopOvers')}
                >
                  minus_circle
                </button>
                <input
                  className="pl-3 h-8 ml-1 flex-1 search-input place-search py-4 overflow-ellipsis pr-7"
                  value={stopOver.region}
                  placeholder="경유지를 검색해주세요"
                  onChange={(e) => {
                    setPostCode(e.target.value, 'stopOvers', `${stopOver.id}`);
                  }}
                  onFocus={onFocus}
                />
                <span
                  className="input-clear-button mr-6"
                  onClick={() => {
                    setPostCode('', 'stopOvers', `${stopOver.id}`);
                    setFocused(false);
                  }}
                />
              </div>
              {searchResult('stopOvers', stopOver.id)}
            </div>
          ))}

        <div className="relative">
          <div className="flex px-4 my-2">
            <input
              className={`pl-3 flex-1 search-input place-search overflow-ellipsis ${disabled && 'disabled'}`}
              value={destination || ''}
              placeholder="목적지를 검색해주세요"
              onChange={(e) => setPostCode(e.target.value, 'destination', null)}
              onFocus={onFocus}
            />
            <span
              className="input-clear-button mr-6"
              onClick={() => {
                setScheduleByType('destination', '');
                setFocused(false);
              }}
            />
          </div>
          {searchResult('destination')}
        </div>
        {!disabled && stopOvers && stopOvers.length < maxStopOverLength && (
          <div className="px-4">
            <a onClick={() => addStopOver('stopOvers')}>
              <img src={stopoverBtn} alt="" style={{ height: '45px' }} />
            </a>
          </div>
        )}
      </div>
    </List>
  );
};

export default DetailContainer;
