import React from 'react';
import { Page, Navbar, PageContent, List, Panel, Link } from 'framework7-react';
import S3ImageView from '@components/images/S3ImageView';

const DesktopPanel = ({ currentUser, handleLogout, isLoggedIn = false }) => {
  const { email, name, profile = { key: '', level: '' } } = currentUser;
  const isNormal = currentUser.isAuthenticated && currentUser.user_type === 'normal';
  const isDriver = currentUser.isAuthenticated && currentUser.user_type === 'driver';

  return (
    <Panel left cover opened={!!isLoggedIn} className="shadow">
      <Page>
        <Navbar title="메뉴" />
        <PageContent>
          <a href="#" className="mb-8 my-2 mx-2 px-3 flex-shrink-0 group block">
            <div className="flex items-center">
              <div>
                {isLoggedIn && profile ? (
                  <S3ImageView imageKey={profile.key} className="w-16 h-16 rounded-full" />
                ) : (
                  <i className="las la-user-circle" style={{ fontSize: '64px', color: '#374151' }} />
                )}
              </div>
              <div className="ml-3">
                <p className="text-lg font-medium text-gray-700 group-hover:text-gray-900">
                  {isLoggedIn ? name : '배낭버스'}
                </p>
                <p
                  className="text-sm font-normal text-gray-500 group-hover:text-gray-700"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {isLoggedIn ? email : ''}
                </p>
              </div>
            </div>
          </a>
          <List>
            <div className="mt-4">
              {isNormal && (
                <Link
                  href="/normal-reservations"
                  className="flex justify-start text-base font-medium text-gray-500 hover:text-gray-900 px-6 py-4"
                  panelClose
                  view="current"
                >
                  예약
                </Link>
              )}
              {isDriver && (
                <Link
                  href="/driver-reservations"
                  className="flex justify-start text-base font-medium text-gray-500 hover:text-gray-900 px-6 py-4"
                  panelClose
                  view="current"
                >
                  예약
                </Link>
              )}
              {isLoggedIn && (
                <>
                  <Link
                    href="/rooms"
                    className="flex justify-start text-base font-medium text-gray-500 hover:text-gray-900 px-6 py-4"
                    panelClose
                    view="current"
                  >
                    채팅
                  </Link>
                  <Link
                    href="/mypage"
                    className="flex justify-start text-base font-medium text-gray-500 hover:text-gray-900 px-6 py-4"
                    panelClose
                    view="current"
                  >
                    마이페이지
                  </Link>
                  <Link
                    onClick={handleLogout}
                    className="flex justify-start text-base font-medium text-gray-500 hover:text-gray-900 px-6 py-4"
                    panelClose
                  >
                    로그아웃
                  </Link>
                </>
              )}
            </div>
          </List>
        </PageContent>
      </Page>
    </Panel>
  );
};

export default DesktopPanel;
