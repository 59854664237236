import {
  activateChatroomAPI,
  createChatroomAPI,
  createPayment,
  createUserChatroomAPI,
  paymentAgencyAPI,
  updateReservation,
} from '@api';
import { currentUserState } from '@atoms';
import ScheduleDisplay from '@components/schedule/scheduleDisplay';
import { CalculateStatus, REACT_QUERY_KEYS, ReservationStatus } from '@constants';
import { CurrentUser } from '@interfaces';
import { currency } from '@js/utils';
import { buildInitMessageForUser } from '@utils';
import {
  AccordionContent,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Col,
  f7,
  List,
  ListItem,
  Row,
} from 'framework7-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { BiBus, BiWon } from 'react-icons/bi';
import { FaUserTie } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { IS_PRODUCTION } from '@config';

const findOrCreateChatRoom = async (
  currentUser: CurrentUser,
  targetUserUuid: string,
  driver,
  sendInitMessageForUser,
) => {
  const { data: chatRoom } = await createChatroomAPI({
    user_uuid: targetUserUuid,
    room_type: 'single',
  });

  const user_chatroom = [currentUser.uuid, targetUserUuid].map((v) => ({ user_uuid: v }));
  const { data: userChatRoom } = await createUserChatroomAPI(chatRoom.id, { user_chatroom });
  const isRoomCreated = userChatRoom.user_uuids.find((v) => v !== currentUser.uuid);
  if (!isRoomCreated) throw new Error('상대방을 찾을 수 없습니다');

  await activateChatroomAPI(chatRoom.id);
  const message = buildInitMessageForUser(currentUser, driver);
  sendInitMessageForUser({ message, targetUserUuid, presentUser: currentUser, roomId: chatRoom.id });

  return chatRoom;
};

const DriverReservation = ({ reservation, refetch, sendInitMessageForUser }) => {
  const queryClient = useQueryClient();
  const currentUser = useRecoilValue(currentUserState);
  const {
    id,
    departure,
    arrival,
    departureDate,
    departureTime,
    status,
    calculate_status: calculateStatus,
    returnDate,
    returnTime,
    total_price,
    payment_amount,
    total_distance,
    people,
    schedules,
    createdAt,
    remainPaymentMethod,
    driver,
    user: targetUser,
  } = reservation;

  const handleButton = async (param) => {
    if (param.status === 'rejected') {
      f7.dialog.prompt(`거절 사유를 입력해주세요`, `예약 거절 하시겠어요?`, async (res) => {
        f7.preloader.show();
        let message: string;
        try {
          const { reservationId } = param;
          const updatedReservation = await updateReservation({ ...param, reject_message: res }, reservationId);
          message = `예약 거절 완료되었습니다`;
        } catch (error) {
          console.log(error);
          if (typeof error.message === 'string') message = '';
          else message = '예상치 못한 오류가 발생하였습니다';
        } finally {
          f7.preloader.hide();
          f7.dialog.alert(message);
          refetch();
          await queryClient.invalidateQueries(REACT_QUERY_KEYS.MY_CHATROOMS);
        }
      });
    } else {
      f7.dialog.confirm(
        `${isEmpty(param.calculate_status) ? ReservationStatus[param.status] : '운행 종료'} 하시겠어요?`,
        async () => {
          f7.preloader.show();
          let message: string;
          try {
            const { reservationId } = param;
            if (param.status === 'done' && IS_PRODUCTION) {
              await createPayment({
                ...param,
                user: targetUser,
              });
            }

            const updatedReservation = await updateReservation(param, reservationId);
            if (updatedReservation && param.status === 'done') {
              await findOrCreateChatRoom(currentUser, targetUser.uuid, driver, sendInitMessageForUser);
            }
            message = `${isEmpty(param.calculate_status) ? ReservationStatus[param.status] : '운행 종료'} 하였습니다`;
          } catch (error) {
            console.log(error);
            if (typeof error.message === 'string') message = '';
            else message = '예상치 못한 오류가 발생하였습니다';
          } finally {
            f7.preloader.hide();
            f7.dialog.alert(message);
            refetch();
            await queryClient.invalidateQueries(REACT_QUERY_KEYS.MY_CHATROOMS);
          }
        },
      );
    }
  };
  const reservationLabel = (label: ReservationStatus | CalculateStatus) => {
    switch (label) {
      case '대기':
        return (
          <div className="text-secondary text-sm font-semibold border border-solid border-secondary rounded-full px-2.5">
            {label}
          </div>
        );
      case '예약 완료':
        if (calculateStatus === 'waiting') {
          return (
            <div className="text-sm font-semibold border-gray-400 border border-solid rounded-full px-2.5">
              정산 대기 중
            </div>
          );
        }
        if (calculateStatus === 'done')
          return (
            <div className="text-sm font-semibold border-gray-400 border border-solid rounded-full px-2.5">
              정산완료
            </div>
          );
        return (
          <div className="text-green-500 text-sm font-semibold border border-solid border-green-500 rounded-full px-2.5">
            {label}
          </div>
        );
      case '운행 종료 확인':
        return (
          <div className="text-sm font-semibold border-gray-400 border border-solid rounded-full px-2.5">{label}</div>
        );
      case '예약 취소':
      case '예약 거절':
        return (
          <div className="text-red-500 text-sm font-semibold border border-solid border-red-500 rounded-full px-2.5">
            {label}
          </div>
        );
      default:
        return '';
    }
  };
  return (
    <Card className="bg-white mb-5 rounded relative h-auto driver-reservation-card border border-background">
      <CardHeader className="no-border">
        <div className="mt-2 w-full">
          <div className="flex items-center justify-between w-full mb-2">
            <div className="flex items-center">
              <p className="text-sm font-semibold mr-2">{moment(createdAt).format('YYYY.MM.DD')}</p>
              {reservationLabel(ReservationStatus[status])}
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Row>
          <Col width="30">출발지</Col>
          <Col width="60" className="text-base font-bold text-gray-900">
            {schedules[0]?.departure}
          </Col>
          <Col width="10" />
        </Row>
        <Row className="mb-5">
          <Col width="30">도착지</Col>
          <Col width="60" className="text-base font-bold text-gray-900">
            {schedules[0]?.destination}
          </Col>
          <Col width="10" />
        </Row>
        <Row className="mb-2">
          <Col width="30">
            <AiOutlineCalendar className="text-secondary inline text-xl  pb-1 mr-1" />
            <span className="text-secondary text-base">가는날</span>
          </Col>
          <Col width="70" className="text-base font-medium">
            {`${moment(departureDate).format('YYYY년 MM월 DD일')} ${departureTime}`}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col width="30">
            <AiOutlineCalendar className="text-secondary inline text-xl  pb-1 mr-1" />
            <span className="text-secondary text-base">오는날</span>
          </Col>
          <Col width="70" className="text-base font-medium">
            {`${moment(returnDate).format('YYYY년 MM월 DD일')} ${returnTime}`}
          </Col>
        </Row>
        <List
          accordionList
          className="pt-4"
          style={{ padding: 'var(--f7-card-content-padding-vertical) var(--f7-card-content-padding-horizontal)' }}
        >
          <ListItem accordionItem accordionItemOpened>
            <div slot="title" className="font-bold">
              상세정보
            </div>
            <AccordionContent>
              <ScheduleDisplay tourSchedule={schedules} type="reservation" isOpen />
              <div className="py-2 terms">
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <IoPerson className="text-primary text-xl mr-2" />
                    <span className="text-base">차량번호</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{driver.bus.bus_number}</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <IoPerson className="text-primary text-xl mr-2" />
                    <span className="text-base">인원</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{people}</span>인
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <BiBus className="text-primary text-xl mr-2" />
                    <span className="text-base">총 거리</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{total_distance}</span>km
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">견적금액</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{currency(total_price)}</span>원
                    <span className="text-sm">(VAT포함)</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">기사님 결제금액</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{currency(total_price - payment_amount)}</span>원
                    <span className="text-sm">(이용일자 현장결제)</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">결제 방법</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{remainPaymentMethod === 'cash' ? '계좌이체' : '카드결제'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center">
                    <FaUserTie className="text-primary text-xl mr-2" />
                    <span className="text-base">기사 정보</span>
                  </Col>
                  <Col width="50" className="text-base">
                    <span className="font-bold">{driver?.name}&nbsp;</span>기사님
                  </Col>
                </Row>
              </div>
            </AccordionContent>
          </ListItem>
        </List>
      </CardContent>
      <CardFooter>
        {!!currentUser?.director_email && driver?.management_type === 'managed' && (
          <>
            {status === 'waiting' && (
              <>
                <Button
                  className="text-red-400 border border-red-400"
                  onClick={() => handleButton({ reservationId: id, status: 'rejected', driver })}
                  text="거절"
                />
                <Button
                  className="text-green-400 border border-green-400"
                  onClick={() =>
                    handleButton({
                      reservationId: id,
                      status: 'done',
                      driver,
                      calculateStatus,
                      payment_amount: payment_amount || Math.floor(total_price * 0.1),
                      total_price,
                      // total_price: Math.floor(total_price - total_price * 0.2),
                    })
                  }
                  text="수락"
                />
              </>
            )}
            {status === 'done' && calculateStatus === 'yet' && (
              <Button
                className="text-black border border-black"
                onClick={() =>
                  handleButton({
                    reservationId: id,
                    status: 'done',
                    driver,
                    calculate_status: 'waiting',
                  })
                }
                text="운행종료"
              />
            )}
            {(status === 'rejected' || calculateStatus === 'done') && (
              <Button
                className="text-black"
                onClick={() =>
                  handleButton({
                    reservationId: id,
                    status: 'deleted',
                    driver,
                    total_price: Math.floor(total_price - total_price * 0.1),
                  })
                }
                text="삭제"
              />
            )}
          </>
        )}
        {!currentUser?.director_email && driver?.management_type === 'none' && (
          <>
            {status === 'waiting' && (
              <>
                <Button
                  className="text-red-400 border border-red-400"
                  onClick={() => handleButton({ reservationId: id, status: 'rejected', driver })}
                  text="거절"
                />
                <Button
                  className="text-green-400 border border-green-400"
                  onClick={() =>
                    handleButton({
                      reservationId: id,
                      status: 'done',
                      calculateStatus,
                      total_price,
                      payment_amount: payment_amount || Math.floor(total_price * 0.1),
                      driver,
                      // total_price: Math.floor(total_price - total_price * 0.2),
                    })
                  }
                  text="수락"
                />
              </>
            )}
            {status === 'done' && calculateStatus === 'yet' && (
              <Button
                className="text-black border border-black"
                onClick={() =>
                  handleButton({
                    reservationId: id,
                    status: 'done',
                    driver,
                    calculate_status: 'waiting',
                  })
                }
                text="운행종료"
              />
            )}
            {(status === 'rejected' || calculateStatus === 'done') && (
              <Button
                className="text-black"
                onClick={() =>
                  handleButton({
                    reservationId: id,
                    status: 'deleted',
                    driver,
                    total_price: total_price - Math.floor(total_price * 0.1),
                  })
                }
                text="삭제"
              />
            )}
          </>
        )}
      </CardFooter>
    </Card>
  );
};

export default DriverReservation;
