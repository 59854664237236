/* eslint-disable react-hooks/rules-of-hooks */
import { Auth } from '@aws-amplify/auth';
import { configs } from '@config';
import React, { useEffect, useRef, useState } from 'react';
import {
  f7,
  Navbar,
  Page,
  List,
  ListInput,
  ListItem,
  AccordionContent,
  Chip,
  Block,
  Toggle,
  Link,
} from 'framework7-react';
import { formatCurrency, sleep } from '@utils';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useRecoilState } from 'recoil';
import { currentUserState } from '@atoms';
import i18next from 'i18next';
import { showToast } from '@js/utils';
import { keys, pick } from 'lodash';
import S3ImagePicker from '@components/images/S3ImagePicker';
import { BusBirth, BusTypes, Regions, NightBegin, NightEnd } from '@constants';
import { MainPlaceHolder } from '@components/images';
import { IoCloseCircleSharp } from 'react-icons/io5';
import S3MultiImagePicker from '@components/images/S3MultiImagePicker';
import { updateAPI } from '@api';
import backIcon from '@assets/images/back_icon.png';

const UserInfoSchema = Yup.object().shape({
  peopleAvailable: Yup.number().typeError('숫자만 입력해주세요').required('필수 입력사항 입니다'),
  basicKm: Yup.number().typeError('숫자만 입력해주세요').required('필수 입력사항 입니다'),
  basicCharge: Yup.string().required('필수 입력사항 입니다'),
  chargePerKm: Yup.string().required('필수 입력사항 입니다'),
  chargePerDay: Yup.string().required('필수 입력사항 입니다'),
  serviceCharge: Yup.string().required('필수 입력사항 입니다'),
  nightBegin: Yup.string().required('필수 입력사항 입니다'),
  nightEnd: Yup.string().required('필수 입력사항 입니다'),
  nightCharge: Yup.string().required('필수 입력사항 입니다'),
  busType: Yup.string().required('필수 입력사항 입니다'),
  busOld: Yup.string().required('필수 입력사항 입니다'),
  busNumber: Yup.string().required('필수 입력사항 입니다'),
  introduce: Yup.string(),
  peakCharge: Yup.string().required('필수 입력사항 입니다'),
  peakChargePerKm: Yup.string().required('필수 입력사항 입니다'),
  wifi: Yup.boolean(),
  sanitizer: Yup.boolean(),
  fridge: Yup.boolean(),
  usb: Yup.boolean(),
  movie: Yup.boolean(),
  audio: Yup.boolean(),
  company_name: Yup.string().required('필수 입력사항 입니다'),
});

const DriverEditPage = ({ f7router }) => {
  const [removedIds, setRemovedIds] = useState<number[]>([]);
  const removeIds = useRef(removedIds);

  const [removedKey, setRemovedKey] = useState('');
  const removeKey = useRef(removedKey);

  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [drivableRegion, setDrivableRegion] = useState(
    currentUser.drivable_region ? [...currentUser.drivable_region] : [],
  );
  const { profile, company_name, bus } = currentUser;

  let bus_profiles;
  if (bus && bus?.profiles) {
    bus_profiles = bus?.profiles;
  }

  const handleArrayChange = (e, arrayName) => {
    const { value } = e.target;
    const valueIndex = arrayName.indexOf(value);
    const duplicatedArr = JSON.parse(JSON.stringify(arrayName));
    if (valueIndex !== -1) {
      duplicatedArr.splice(valueIndex, 1);
      if (arrayName === drivableRegion) {
        setDrivableRegion(duplicatedArr);
      }
    } else {
      duplicatedArr.push(value);
      if (arrayName === drivableRegion) {
        setDrivableRegion(duplicatedArr);
      }
    }
  };

  useEffect(() => {
    removeIds.current = removedIds;
  }, [removedIds]);
  return (
    <Page noToolbar>
      <Navbar title="기사정보 관리" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>

      <Formik
        enableReinitialize
        initialValues={{
          company_name,
          profileImg: currentUser.profile || '',
          busNumber: currentUser.bus?.bus_number || '',
          busType: currentUser.bus?.bus_type || '대형',
          busOld: currentUser.bus?.bus_old || 2010,
          busProfiles: currentUser.bus?.profiles || [],
          peopleAvailable: currentUser.bus?.people_available || null,
          introduce: currentUser.introduce || '',
          basicCharge: currentUser.basic_charge || 500000,
          basicKm: currentUser.basic_km || 50,
          chargePerKm: currentUser.charge_per_km || 1800,
          nightCharge: currentUser.night_charge || 30000,
          nightBegin: currentUser.night_begin || 21,
          nightEnd: currentUser.night_end || 4,
          chargePerDay: currentUser.charge_per_day || 600000,
          serviceCharge: currentUser.service_charge || 30000,
          peakCharge: currentUser.peak_charge || 600000,
          peakChargePerKm: currentUser.peak_charge_per_km || 1800,
          wifi: currentUser.bus?.wifi || false,
          sanitizer: currentUser.bus?.sanitizer || false,
          fridge: currentUser.bus?.fridge || false,
          usb: currentUser.bus?.usb || false,
          movie: currentUser.bus?.movie || false,
          audio: currentUser.bus?.audio || false,
          drivableRegion: currentUser.drivable_region || '',
        }}
        validationSchema={UserInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          f7.dialog.preloader('잠시만 기다려주세요...');
          try {
            [
              'basicCharge',
              'chargePerKm',
              'nightCharge',
              'chargePerDay',
              'serviceCharge',
              'peakCharge',
              'peakChargePerKm',
            ].forEach((priceType) => {
              values[priceType] = `${values[priceType]}`.replace(/[^\d.]+/g, '');
              console.log(priceType, values[priceType], typeof values[priceType]);
            });
            if (values.profileImg || profile) {
              const profileImg = values.profileImg || profile;
              values.profileImg = pick(profileImg?.key !== removeKey.current ? profileImg : {}, ['key']);
            }

            if (currentUser.bus?.profiles || values.busProfiles?.length) {
              const tempBusProfiles = values.busProfiles.length === 0 ? currentUser.bus.profiles : values.busProfiles;
              values.busProfiles = tempBusProfiles.filter((el) => removeIds.current.indexOf(el.id) === -1);
            }

            values.drivableRegion = drivableRegion;

            if (!(values.drivableRegion.length > 0)) {
              f7.dialog.close();
              f7.dialog.alert('출발가능지역 최소 1개 지역을 선택해주세요');
              return '';
            }

            const { data: user } = await updateAPI(values);
            setCurrentUser({ ...user, isAuthenticated: true });
            showToast('정보가 변경되었습니다');
          } catch (error) {
            let msg = '오류가 발생했습니다. 다시 시도해주세요';
            if (error.response.data?.message) {
              msg = error.response.data.message;
            } else if (error.response.data?.error?.message) {
              msg = error.response.data?.error?.message;
            }

            f7.dialog.alert(msg);
          } finally {
            f7.dialog.close();
          }

          return '';
        }}
        validateOnMount
      >
        {({ handleChange, handleBlur, values, errors, touched, isSubmitting, isValid, setFieldValue }) => (
          <Form encType="multipart/form-data" className="registration-form">
            <List noHairlinesMd>
              <div className="p-3 font-semibold bg-white">기본 정보</div>
              <div className="p-3 bg-white">프로필 사진을 등록 해주세요</div>
              <div
                className="flex flex-col items-center bg-white"
                style={{
                  paddingLeft:
                    'calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) - var(--menu-list-offset))',
                  paddingRight:
                    'calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) - var(--menu-list-offset))',
                }}
              >
                <S3ImagePicker
                  isMultiple={false}
                  initialData={profile ? [profile] : undefined}
                  placeholderComponent={<MainPlaceHolder maxCount={1} isImage />}
                  deleteButtonComponent={<IoCloseCircleSharp size={26} className="text-black bg-white rounded-full" />}
                  removeImageHandler={(key, removedS3Image) => {
                    setRemovedKey(() => key);
                    removeKey.current = key;
                  }}
                  containerClassName="w-full relative rounded-md overflow-hidden"
                  addImageHandler={(v: any) => {
                    if (v[0]) {
                      setFieldValue('profileImg', v[0]);
                    }
                    setRemovedKey((prev) => '');
                  }}
                />
                <p className="text-sm pt-3" style={{ color: '#666' }}>
                  * 신뢰감을 줄 수 있는 밝은 느낌의 사진이면 좋습니다
                </p>
              </div>

              <ListInput
                outline
                label={i18next.t('소속회사') as string}
                type="text"
                name="company_name"
                onChange={handleChange}
                value={values.company_name}
              />
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="차량정보" className="font-bold bg-white">
                <AccordionContent>
                  <div className="p-4">
                    <label className="text-sm font-semibold block pb-1">차량 사진</label>
                    <span className="text-secondary text-sm">&#x0002A;운행하는 차량의 실물을 올려주세요</span>
                  </div>
                  <S3MultiImagePicker
                    isMultiple
                    maxCount={10}
                    initialData={bus_profiles ? [...bus_profiles] : undefined}
                    placeholderComponent={<MainPlaceHolder maxCount={10} isImage />}
                    deleteButtonComponent={
                      <IoCloseCircleSharp size={26} className="text-secondary bg-white rounded-full" />
                    }
                    removeImageHandler={(key, removedS3Image) => {
                      if (removedS3Image) setRemovedIds((prev) => [...prev, removedS3Image.id]);
                    }}
                    containerClassName="w-full h-2/5 relative"
                    addImageHandler={(v: any) => {
                      setFieldValue('busProfiles', v);
                      setRemovedIds(() => []);
                    }}
                  />
                  <ListInput
                    label={i18next.t('차량번호') as string}
                    outline
                    type="text"
                    name="busNumber"
                    placeholder="예: 서울12가1234"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.busNumber}
                    errorMessageForce
                    errorMessage={touched.busNumber && errors.busNumber}
                  />
                  <ListInput
                    label={i18next.t('가용승객수') as string}
                    outline
                    type="text"
                    name="peopleAvailable"
                    placeholder="가용 승객수를 숫자만 입력해주세요"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.peopleAvailable}
                    errorMessageForce
                    errorMessage={touched.peopleAvailable && errors.peopleAvailable}
                  />
                  <ListInput
                    label={i18next.t('차량유형') as string}
                    outline
                    type="select"
                    name="busType"
                    defaultValue="대형"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.busType}
                    errorMessageForce
                    errorMessage={touched.busType && errors.busType}
                  >
                    {BusTypes.map((busType) => (
                      <option key={busType} value={busType}>
                        {busType}
                      </option>
                    ))}
                  </ListInput>
                  <ListInput
                    label={i18next.t('차량연식') as string}
                    outline
                    type="select"
                    name="busOld"
                    defaultValue="2011년식"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.busOld}
                    errorMessageForce
                    errorMessage={touched.busOld && errors.busOld}
                  >
                    {BusBirth.map((birth) => (
                      <option key={birth} value={birth}>
                        {birth}년식
                      </option>
                    ))}
                  </ListInput>
                </AccordionContent>
              </ListItem>
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="출발가능지역 (복수선택 가능)">
                <AccordionContent>
                  {keys(Regions).map((key) => {
                    const regions = Regions[key].map((region) => (
                      <ListItem
                        checkbox
                        key={region}
                        onChange={(e) => handleArrayChange(e, drivableRegion)}
                        value={region}
                        title={region}
                        defaultChecked={currentUser.drivable_region?.includes(region)}
                        name={`${key}-checkbox`}
                      />
                    ));

                    return (
                      <div className="accordion-list">
                        <ListItem accordionItem title={key} className="drivable-region">
                          <AccordionContent>{regions}</AccordionContent>
                        </ListItem>
                      </div>
                    );
                  })}
                </AccordionContent>
              </ListItem>
              <Block strong className="ml-3 mt-1">
                {drivableRegion.map((region) => (
                  <Chip outline key={region} className="mr-1 text-secondary" text={region} />
                ))}
              </Block>
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="운행요금" className="font-bold bg-white">
                <AccordionContent>
                  <ListInput
                    label={i18next.t('기본요금') as string}
                    outline
                    type="text"
                    name="basicCharge"
                    placeholder="숫자만 입력해주세요 (예 : 300000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.basicCharge)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.basicCharge && errors.basicCharge}
                  />
                  <ListInput
                    label={i18next.t('기본운행거리(km)') as string}
                    outline
                    type="text"
                    name="basicKm"
                    placeholder="숫자만 입력해주세요 (예 : 100)"
                    inputmode="numeric"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.basicKm}
                    errorMessageForce
                    errorMessage={touched.basicKm && errors.basicKm}
                  />
                  <ListInput
                    label={i18next.t('km당 단가') as string}
                    outline
                    type="text"
                    name="chargePerKm"
                    placeholder="숫자만 입력해주세요 (예 : 1000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.chargePerKm)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.chargePerKm && errors.chargePerKm}
                  />
                  <ListInput
                    label={i18next.t('1박 추가시 추가요금') as string}
                    outline
                    type="text"
                    name="chargePerDay"
                    placeholder="숫자만 입력해주세요 (예 : 400000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.chargePerDay)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.chargePerDay && errors.chargePerDay}
                  />
                  <ListInput
                    label={i18next.t('봉사료') as string}
                    outline
                    type="text"
                    name="serviceCharge"
                    placeholder="숫자만 입력해주세요 (예 : 50000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.serviceCharge)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.serviceCharge && errors.serviceCharge}
                  />
                </AccordionContent>
              </ListItem>
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="할증요금" className="font-bold bg-white">
                <AccordionContent>
                  <ListInput
                    label={i18next.t('성수기 기본요금') as string}
                    outline
                    type="text"
                    name="peakCharge"
                    placeholder="숫자만 입력해주세요 (예 : 500000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.peakCharge)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.peakCharge && errors.peakCharge}
                  />
                  <ListInput
                    label={i18next.t('성수기 km당 요금') as string}
                    outline
                    type="text"
                    name="peakChargePerKm"
                    placeholder="숫자만 입력해주세요 (예 : 2000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.peakChargePerKm)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.peakChargePerKm && errors.peakChargePerKm}
                  />
                  <ListInput
                    label={i18next.t('심야시작시간') as string}
                    outline
                    type="select"
                    defaultValue="21시"
                    name="nightBegin"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nightBegin}
                    errorMessageForce
                    errorMessage={touched.nightBegin && errors.nightBegin}
                  >
                    {NightBegin.map((nightBegin) => (
                      <option key={nightBegin} value={nightBegin}>
                        {nightBegin}시
                      </option>
                    ))}
                  </ListInput>
                  <ListInput
                    label={i18next.t('심야종료시간') as string}
                    outline
                    type="select"
                    defaultValue="04시"
                    name="nightEnd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nightEnd}
                    errorMessageForce
                    errorMessage={touched.nightEnd && errors.nightEnd}
                  >
                    {NightEnd.map((nightEnd) => (
                      <option key={nightEnd} value={nightEnd}>
                        0{nightEnd}시
                      </option>
                    ))}
                  </ListInput>
                  <ListInput
                    label={i18next.t('심야시간 추가요금') as string}
                    outline
                    type="text"
                    name="nightCharge"
                    placeholder="숫자만 입력해주세요 (예 : 50000)"
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatCurrency(values.nightCharge)}
                    inputmode="numeric"
                    errorMessageForce
                    errorMessage={touched.nightCharge && errors.nightCharge}
                  />
                </AccordionContent>
              </ListItem>
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="편의시설" className="font-bold bg-white">
                <AccordionContent>
                  <ListItem title="손소독제">
                    <Toggle
                      slot="after"
                      name="sanitizer"
                      onChange={handleChange}
                      defaultChecked={values.sanitizer === true}
                    />
                  </ListItem>
                  <ListItem title="냉장고">
                    <Toggle
                      slot="after"
                      name="fridge"
                      onChange={handleChange}
                      defaultChecked={values.fridge === true}
                    />
                  </ListItem>
                  <ListItem title="음향시설">
                    <Toggle slot="after" name="audio" onChange={handleChange} defaultChecked={values.audio} />
                  </ListItem>
                  <ListItem title="와이파이">
                    <Toggle slot="after" name="wifi" onChange={handleChange} defaultChecked={values.wifi} />
                  </ListItem>
                  <ListItem title="usb포트">
                    <Toggle slot="after" name="usb" onChange={handleChange} defaultChecked={values.usb} />
                  </ListItem>
                  <ListItem title="영화관람">
                    <Toggle slot="after" name="movie" onChange={handleChange} defaultChecked={values.movie} />
                  </ListItem>
                </AccordionContent>
              </ListItem>
            </List>

            <List noHairlinesMd accordionList>
              <ListItem accordionItem title="자기소개" className=" bg-white">
                <AccordionContent>
                  <ListInput
                    type="textarea"
                    outline
                    name="introduce"
                    placeholder="승객에게 표시되는 문구입니다."
                    clearButton
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.introduce}
                    errorMessageForce
                    errorMessage={touched.introduce && errors.introduce}
                  />
                </AccordionContent>
              </ListItem>
            </List>

            {/* <List noHairlinesMd>
              <div className="p-3 font-semibold bg-white">자기소개</div>
            </List> */}
            {/*
            <List noHairlinesMd>
              <div className="p-3 font-semibold bg-white">차량 정보</div>
              
              <List noHairlinesMd>
                <div className="p-3 font-semibold bg-white">운행단가 정보</div>
              </List>

              <List noHairlinesMd>
                <div className="p-3 font-semibold bg-white">성수기운행 정보</div>
              </List>
              
              <List noHairlinesMd>
                <div className="p-3 font-semibold bg-white">심야운행 정보</div>
              </List>

              <List noHairlinesMd>
                <div className="p-3 font-semibold bg-white">편의시설</div>
              </List>
            </List> */}

            {/* <List noHairlinesMd>
              <div className="p-3 font-semibold bg-white">차량사진</div>
            </List> */}
            <div className="p-4">
              <button
                type="submit"
                className="button button-fill button-large"
                onClick={() => {
                  const error_key = Object.keys(errors);
                  if (!isValid && keys.length > 0) {
                    const key = error_key[0];
                    const elem = document.querySelector(`.page-current input[name='${key}']`);
                    const text = elem?.parentElement?.previousElementSibling?.innerHTML;
                    f7.dialog.alert(`${text}에서 빈칸을 채워주세요`);
                  }
                }}
              >
                정보수정
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default DriverEditPage;
