import { createReservation, getDriverDynamicLink, getOneDriver, getReviews } from '@api';
import { searchingOptionState, totalChargeState, tourScheduleState } from '@atoms';
import BusOption from '@components/drivers/BusOption';
import ClacInfo from '@components/drivers/ClacInfo';
import PriceInfo from '@components/drivers/PriceInfo';
import S3ImageView from '@components/images/S3ImageView';
import SingleReview from '@components/reviews/SingleReview';
import ScheduleDisplay from '@components/schedule/scheduleDisplay';
import ScheduleTimeDisplay from '@components/schedule/scheduleTimeDisplay';
import Footer from '@components/shared/Footer';
import ImageSlide from '@components/shared/ImageSlide';
import useAuth from '@hooks/useAuth';
import { Bus, CurrentUser } from '@interfaces';
import {
  currency,
  deleteReservationInformationAtLocalStorage,
  setMainViewHash,
  setReservationInformationAtLocalStorage,
  showToast,
} from '@js/utils';
import { formatCurrency, shareByKakao } from '@utils';
import {
  AccordionContent,
  Button,
  Checkbox,
  f7,
  Link,
  List,
  ListItem,
  Page,
  Tab,
  Tabs,
  Toolbar,
  Input,
} from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl';
import { IoShareOutline } from 'react-icons/io5';
import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment/moment';
import backWhiteIcon from '@assets/images/back_white.png';

const CancelNotice = ({ deDate }) => {
  if (moment().format('YYYY-DD-MM') === moment(deDate).format('YYYY-DD-MM')) {
    return <p className="">{moment().format('YYYY년 MM월 DD일')}까지는 취소 수수료 없음</p>;
  }

  return (
    <>
      {moment(deDate).diff(moment().format('YYYY-MM-DD'), 'days') < 5 ? (
        <>
          <li className="">{moment().format('YYYY년 MM월 DD일')}까지는 취소 수수료 없음</li>
          <li className="">
            {moment().add(1, 'days').format('YYYY년 MM월 DD일')}부터는 예약금액의 10% 취소 수수료 발생
          </li>
        </>
      ) : (
        <>
          <li className="">{moment(deDate).subtract(5, 'days').format('YYYY년 MM월 DD일')}까지는 취소 수수료 없음</li>
          <li className="">
            {moment(deDate).subtract(4, 'days').format('YYYY년 MM월 DD일')}부터는 예약금액의 10% 취소 수수료 발생
          </li>
        </>
      )}
    </>
  );
};

const DriverShowPage = ({ id, f7route, f7router }) => {
  const { totalDistance } = useRecoilValue(searchingOptionState);
  const [searchingOption, setSearchingOption] = useRecoilState(searchingOptionState);
  const { departureDate, departureTime, returnDate, returnTime } = useRecoilValue(searchingOptionState);
  const tourSchedule = useRecoilValue(tourScheduleState);
  const totalCharge = useRecoilValue(totalChargeState);
  const { currentUser } = useAuth();
  const [driver, setDriver] = useState({} as CurrentUser);
  const [remainPaymentMethod, setRemainPaymentMethod] = useState('card');
  const [reviews, setReviews] = useState([]);
  const busTypeObj = {
    small: '소형',
    middle: '중형',
    large: '대형',
  };
  const people = useRef(+f7route.query?.people || 0);

  const handleSubmit = async () => {
    if (currentUser.card_registered === false) {
      f7.dialog.confirm('등록된 카드가 없습니다. 등록하시겠어요?', () => f7router.navigate('/users/card'));
      return;
    }

    if (people.current === 0) {
      showToast('탑승인원을 입력해주세요');
      return;
    }

    f7.preloader.show();

    const { departure } = tourSchedule[0];
    const { destination: arrival } = tourSchedule[tourSchedule.length - 1];

    let callback = () => {};
    let message: string;
    try {
      const reservationParams = {
        userEmail: currentUser.email,
        driverId: Number(id),
        totalDistance,
        totalCharge,
        payment_amount: Math.floor(totalCharge * 0.1),
        remainPaymentMethod,
        people: people.current,
        departure,
        departureDate,
        departureTime,
        arrival,
        returnDate,
        returnTime,
        schedules: tourSchedule,
      };

      await createReservation(reservationParams);

      if (currentUser && currentUser.card_registered === true) {
        deleteReservationInformationAtLocalStorage();
      }

      callback = () => window.location.replace('/?redirect_to=view-reservations');
      message = '기사님께 예약이 전달되었습니다';
      f7.dialog.alert('예약일이 5일이 남지 않은 상태에서 예약을 취소할 경우 결제 금액의 10% 제외한 금액이 환불됩니다.');
    } catch (error) {
      message = '예상치 못한 오류가 발생하였습니다';
      if (error.response) {
        const { data } = error.response;
        message = data.error.message;
      }
    } finally {
      f7.preloader.hide();
      f7.dialog.alert(message, () => callback());
    }
  };

  const setStorageAndRedirect = () => {
    if (people.current === 0) {
      showToast('탑승인원을 입력해주세요');
      return;
    }
    f7.dialog.confirm('예약을 진행하려면 로그인이 필요합니다', () => {
      setReservationInformationAtLocalStorage(totalCharge, tourSchedule, searchingOption, true, people.current, id);
      f7router.navigate('/users/sign_in');
    });
  };

  useEffect(() => {
    async function getTargetDriver() {
      const targetDriver = await getOneDriver(id);
      const { results } = await getReviews({ driver_id: id });
      setReviews(results.flat());
      setDriver(targetDriver);
    }

    getTargetDriver();
  }, [id, setDriver]);

  return (
    <Page
      noToolbar
      name="driverdetail"
      className="driver-show-page bg-gray"
      onPageAfterIn={() => {
        setMainViewHash('out');
      }}
    >
      <Link iconOnly back className="m-4 absolute top-0 z-10">
        <img src={backWhiteIcon} alt="" width="18px" />
      </Link>
      <div className="">
        <div className="relative">
          {window.isDesktop && (
            <>
              <div className="absolute top-1/2 -left-24 driver-navigation-left">
                <SlArrowLeft size={50} color="#0064ff" />
              </div>
              <div className="absolute top-1/2 -right-24 driver-navigation-right">
                <SlArrowRight size={50} color="#0064ff" />
              </div>
            </>
          )}

          <ImageSlide images={driver.bus?.profiles} navigation="driver-navigation">
            <IoShareOutline
              className="absolute top-4 right-4 text-white text-2xl z-[9999]"
              onClick={async () => {
                f7.preloader.show();
                const dynamicLink = await getDriverDynamicLink({ id: driver?.id, shareType: 'driver' });
                shareByKakao(dynamicLink, 'driver', driver?.bus.profiles);
                f7.preloader.hide();
              }}
            />
          </ImageSlide>
        </div>
        <div className="py-5 bg-white">
          <div className="px-4">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0">
                <div className="relative">
                  <S3ImageView className="w-11 h-11 rounded-full overflow-hidden" imageKey={driver?.profile?.key} />
                </div>
              </div>
              <div className="align-items-center">
                <span className="text-lg font-bold mr-1" style={{ color: '#2f3037' }}>
                  {driver.name}
                </span>
                <span className="text-lg" style={{ color: '#2f3037' }}>
                  기사님
                </span>
              </div>
            </div>
            <div className="mt-4 flex items-center space-x-5">
              {driver.introduce ||
                '항상 웃음띤 얼굴로 고객을 대하며 친절과 안전운행으로 처음부터 끝까지 최선을 다하겠습니다.'}
            </div>
            <div className="driver-item-tags flex flex-wrap gap-2 mt-4">
              <div style={{ fontSize: '13px', color: '#5f6064' }}>{driver.bus?.bus_number || ''}</div>
              <div style={{ fontSize: '13px', color: '#5f6064' }}> {driver.bus?.people_available || ''}인승</div>
              <div style={{ fontSize: '13px', color: '#5f6064' }}>
                {driver.bus?.bus_type ? driver.bus.bus_type : '대형'}
              </div>
              <div style={{ fontSize: '13px', color: '#5f6064' }}>
                {driver?.company_name ? `${driver?.company_name}` : '개인'}
              </div>
              <div style={{ fontSize: '13px', color: '#5f6064' }}> {driver.bus?.bus_old || ''}년식</div>
            </div>
          </div>
        </div>
        <Toolbar tabbar top noHairline className="border-t">
          <Link tabLink="#tab-1" tabLinkActive>
            상세정보
          </Link>
          <Link tabLink="#tab-2">고객리뷰</Link>
        </Toolbar>
        <Tabs className="bg-gray">
          <Tab id="tab-1" tabActive>
            <div className="p-4 pt-10">
              {!!totalCharge && (
                <div className="driver-card flex">
                  <div className="driver-card-title">견적</div>
                  <div className="driver-card-total-price">
                    <div>
                      <span>{totalCharge?.toLocaleString()}</span>원
                    </div>
                    <div className="driver-card-total-sub">(부가세 포함)</div>
                  </div>
                </div>
              )}

              <div className="driver-card">
                <div className="driver-card-title">요금안내</div>
                <div className="driver-card-subtitle">자세한 요금 계산방식은 아래 Q&A를 참고해주세요.</div>
                <div className="driver-card-body">{driver && <PriceInfo driver={driver} />}</div>
              </div>
              <div className="driver-card">
                <div className="driver-card-title">계산정보</div>
                <div className="driver-card-subtitle">자세한 요금 계산방식은 아래 Q&A를 참고해주세요.</div>
                <div className="driver-card-body">{driver && <ClacInfo driver={driver} />}</div>
              </div>
              <div className="driver-card">
                <div className="driver-card-title">편의시설</div>
                <div className="driver-card-body">{driver?.bus && <BusOption bus={driver?.bus as Bus} />}</div>
              </div>
              {!!totalCharge && (
                <div>
                  <ScheduleTimeDisplay
                    isOpen
                    tourSchedule={tourSchedule}
                    departureDate={departureDate}
                    departureTime={departureTime}
                    returnDate={returnDate}
                    returnTime={returnTime}
                  />

                  <div className="driver-card flex">
                    <div className="driver-card-title" style={{ lineHeight: '35px' }}>
                      탑승 인원
                    </div>
                    <div className="driver-card-body">
                      <div className="driver-people">
                        <Input
                          type="number"
                          onChange={(e) => {
                            const peopleValue = e.target.value;
                            setSearchingOption({ ...searchingOption, people: peopleValue });
                            people.current = peopleValue;
                          }}
                          value={people.current}
                        />
                        <span>명</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="driver-card">
                <div className="driver-card-title">Q & A</div>
                <List noHairlines accordionList className="my-4 text-sm driver-card-body">
                  <ListItem className="mb-4" accordionItem title="기사님과 연락은 어떻게 할 수 있나요?">
                    <AccordionContent>
                      <div className="px-4 py-3">
                        <p>견적신청 후 기사님과 본 앱을 통해 채팅하실 수 있습니다.</p>
                      </div>
                    </AccordionContent>
                  </ListItem>
                  <ListItem accordionItem title="요금 산출은 어떻게 되는건가요?">
                    <AccordionContent>
                      <div className="px-4 py-3">
                        <p>기본적으로 요금은 기본료와 봉사료를 합산하여 계산됩니다.</p>
                        <p className="pt-2">
                          *여행 일정에 성수기가 포함될 경우, 기사님이 등록한 성수기 기본 요금과 성수기 km당 요금으로
                          적용합니다.*
                        </p>
                        <p className="pt-2">
                          *여행 일정의 총 거리가 기사님이 등록한 기본 운행거리를 초과할 경우 초과된 거리 만큼 km당
                          단가가 곱해져 요금에 합산됩니다.*
                        </p>
                        <p className="pt-2">
                          *일정이 1일을 초과할 경우 초과한 날과 1박시 추가요금이 곱해져 요금에 합산됩니다.*
                        </p>
                        <p className="pt-2">
                          *일정에 심야시간이 포함될 경우 심야 시간과 심야시간 추가요금이 곱해져 요금에 합산됩니다.*
                        </p>
                      </div>
                    </AccordionContent>
                  </ListItem>
                </List>
              </div>
              {!!totalCharge && (
                <>
                  <div className="driver-card">
                    <div className="driver-card-title">예약취소</div>
                    <div className="driver-card-body mt-6">
                      <div>이용일(예약일자): {moment(departureDate).format('YYYY년 MM월 DD일')}</div>

                      <ul className="mt-4">
                        <CancelNotice deDate={departureDate} />
                      </ul>
                    </div>
                  </div>

                  <div className="driver-card">
                    <div className="driver-card-title">결제 금액</div>
                    <div className="driver-card-body">
                      <div className="flex justify-between mt-8">
                        <div>견적수락시 예약금 결제(10%)</div>
                        <div>
                          <span className="font-bold text-black text-sm mr-1">{currency(totalCharge * 0.1)}</span>원
                        </div>
                      </div>

                      <div className="flex justify-between mt-4">
                        <div>운행종료시 현장결제</div>
                        <div>
                          <span className="font-bold text-black text-sm mr-1">
                            {currency(totalCharge - totalCharge * 0.1)}
                          </span>
                          원
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="driver-card">
                    <div className="driver-card-title">이용일 직접결제 방법 (운행종료시 현장결제)</div>
                    <div className="driver-card-body mt-6">
                      <div className="flex items-center mb-2">
                        <div className="text-sm text-gray-900">
                          <Checkbox
                            checked={remainPaymentMethod === 'card'}
                            onChange={() => setRemainPaymentMethod('card')}
                          />
                          <span onClick={() => setRemainPaymentMethod('card')} className="ml-1 text-gray-700 ">
                            카드결제
                          </span>
                        </div>
                        <div className="text-sm text-gray-900 ml-4">
                          <Checkbox
                            checked={remainPaymentMethod === 'cash'}
                            onChange={() => setRemainPaymentMethod('cash')}
                          />
                          <span onClick={() => setRemainPaymentMethod('cash')} className="ml-1 text-gray-700 ">
                            계좌이체
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="driver-card flex">
                    <div className="driver-card-title">요금 총액</div>
                    <div className="driver-card-total-price">
                      <div>
                        <span className="text-primary">{totalCharge?.toLocaleString()}</span>원
                      </div>
                      <div className="driver-card-total-sub">(부가세 포함)</div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Tab>
          <Tab id="tab-2">
            {reviews?.length > 0 ? (
              <div className="my-10">
                {reviews.map((review) => (
                  <SingleReview review={review} />
                ))}
              </div>
            ) : (
              <div className="bg-white text-gray-600 text-center mt-2.5 py-16">아직 리뷰가 없습니다.</div>
            )}
          </Tab>
        </Tabs>
        {!!totalCharge &&
          (currentUser.isAuthenticated ? (
            <div className="z-50 w-full py-4">
              <Button
                text="견적 전달하기"
                className="bg-primary text-white mt-3 mx-4 h-12 text-lg"
                onClick={handleSubmit}
              />
            </div>
          ) : (
            <div className="z-50 w-full py-4">
              <Button
                text="견적 전달하기"
                className="bg-primary text-white mt-3 mx-4 h-12 text-lg"
                onClick={setStorageAndRedirect}
              />
            </div>
          ))}
        {!totalCharge && (
          <div className="z-50 w-full py-4">
            <Button
              text="견적 알아보기"
              className="bg-primary text-white mt-3 mx-4 h-12 text-lg"
              href="/search?back=true&toolbar=hide"
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default DriverShowPage;
