import useAuth from '@hooks/useAuth';
import { Link } from 'framework7-react';
import React, { Fragment } from 'react';
import logo from '../assets/images/logo.png';

export default function Layout({ children }) {
  const { currentUser } = useAuth();

  return (
    <>
      <div
        className="fixed bg-white hidden sm:block w-full border-b border-solid border-indigo-200"
        style={{ zIndex: 10000 }}
      >
        <div className="flex sm:px-6 py-1" style={{ maxWidth: '60rem', margin: '0 auto' }}>
          <div>
            <a href="/" className="flex external">
              <span className="sr-only">배낭버스</span>
              <img src={logo} alt="bus-logo" className="max-h-12" />
            </a>
          </div>

          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-end">
            <div className="flex space-x-10">
              <a href="/notices" className="text-base font-medium text-gray-500 hover:text-gray-900">
                공지사항
              </a>
              <a
                href="https://pf.kakao.com/_vUcrxj/chat"
                target="_blank"
                className="external text-base font-medium text-gray-500 hover:text-gray-900"
                rel="noreferrer"
              >
                1:1 문의하기
              </a>
              <a href="/faqs" className="text-base font-medium text-gray-500 hover:text-gray-900">
                FAQ
              </a>

              <a href="/search" className="text-base font-medium text-gray-500 hover:text-gray-900">
                일정
              </a>
            </div>
            <div className="flex items-center sm:ml-12">
              {currentUser.isAuthenticated ? (
                <div className="space-x-10">
                  <Link className="text-gray-500 hover:text-gray-900" icon="noti_bell" href="/notifications" />
                  <Link className="text-gray-500 hover:text-gray-900" icon="las la-lg bars" panelToggle="left" />
                </div>
              ) : (
                <>
                  <a href="/users/sign_in" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    로그인
                  </a>
                  <a
                    href="/users/sign_up/intro"
                    className="ml-8 inline-flex items-center justify-center px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    회원가입
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {children}
    </>
  );
}
