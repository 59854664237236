import HomeBanner from '@components/shared/HomeBanner';
import { configs } from '@config';
import useRedirect from '@hooks/useRedirect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { f7, Page, Block, BlockTitle, Button, Icon, Link, Navbar, NavLeft, NavRight, NavTitle } from 'framework7-react';
import { useSetRecoilState } from 'recoil';
import { currentUserState } from '@atoms';
import Footer from '@components/shared/Footer';
import usePostBillingProcess from '@hooks/usePostBillingProcess';
import { useQuery } from 'react-query';
import { getDriversByRegion } from '@api';
import { setMainViewHash, sleep } from '@js/utils';
import { GoLocation } from 'react-icons/go';
import useAuth from '@hooks/useAuth';
import useAppleLogin from '@hooks/useAppleLogin';
import useShareDynamicLink from '@hooks/useShareDynamicLink';
import Cookies from 'js-cookie';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from '@assets/images/logo.png';
import bell from '@assets/images/bell_solid.png';
import menu from '@assets/images/menu.png';
import searchIcon from '@assets/images/search.png';
import DriverListItem from './users/driverListItem';

declare global {
  interface Window {
    Android: any;
    webkit: any;
  }
}

const swiperDefaultProps: SwiperOptions = {
  spaceBetween: 10,
  slidesPerView: 1,
  speed: 600,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    clickable: true,
  },
};

const HomePage = ({ f7route, f7router }) => {
  const { currentUser, authenticateUser } = useAuth();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const [errorText, setErrorText] = useState('위치 정보를 확인중입니다!');
  const currentCoordinates = useRef({ x: '', y: '' });
  useAppleLogin(f7router, f7route.query, authenticateUser);
  usePostBillingProcess(f7route.query, setCurrentUser);
  useRedirect(f7route.query);
  useShareDynamicLink(f7route.query, f7router, f7);

  const { data, refetch } = useQuery('driversByRegion', async () => {
    if (!currentCoordinates.current.x) return '';
    const { x, y } = currentCoordinates.current;
    const response = await getDriversByRegion(x, y);
    return response.data;
  });

  const drivers: any[] = useMemo(() => data || false, [data]);

  const locationErr = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        f7.dialog.confirm(
          '위치 권한이 없습니다. <br> 위치 추적 권한을 허용해주세요',
          '',
          () => {
            if (window.Android) {
              window.location.href = 'package:root=location-settings';
            } else if (typeof window.webkit !== 'undefined') {
              window.location.href = 'app-settings:root=LOCATION_SERVICES';
            }
          },
          () => {
            Cookies.set('location-deny', true);
            currentCoordinates.current = { x: '126.975817', y: '37.553881' };
            refetch();
          },
        );
        setErrorText('위치 권한이 없습니다. 위치 권한을 허용해주세요');
        f7.preloader.hide();
        break;
      case error.POSITION_UNAVAILABLE:
        f7.dialog.confirm(
          '위치 권한이 없습니다. <br> 위치 추적 권한을 허용해주세요',
          '',
          () => {
            if (window.Android) {
              window.location.href = 'package:root=location-settings';
            } else if (typeof window.webkit !== 'undefined') {
              window.location.href = 'app-settings:root=LOCATION_SERVICES';
            }
          },
          () => {
            Cookies.set('location-deny', true);
            currentCoordinates.current = { x: '126.975817', y: '37.553881' };
            refetch();
          },
        );
        setErrorText('위치 권한이 없습니다. 위치 권한을 허용해주세요');
        f7.preloader.hide();
        break;
      case error.TIMEOUT:
        f7.dialog.alert('시간 초과입니다. <br> 다시 한번 시도해주세요');
        setErrorText('시간 초과입니다. 다시 한번 시도해주세요');
        break;
      case error.UNKNOWN_ERROR:
        f7.dialog.alert('알 수 없는 에러입니다.');
        setErrorText('알 수 없는 에러입니다');
        break;
      default:
        break;
    }
  };

  const getCoords = (callback?: () => void) => {
    if (navigator.geolocation && Cookies.get('location-deny') !== 'true') {
      navigator.geolocation.getCurrentPosition((position) => {
        currentCoordinates.current = { x: String(position.coords.longitude), y: String(position.coords.latitude) };
        callback();
      }, locationErr);
    } else {
      currentCoordinates.current = { x: '126.975817', y: '37.553881' };
      callback();
    }
  };

  const onRefresh = async (done) => {
    if (!currentCoordinates.current.x) {
      getCoords(refetch);
    }
    await sleep(500);
    done();
  };

  useEffect(() => {
    getCoords(refetch);
  }, [currentUser]);

  useEffect(() => {
    if (f7route.query.hasOwnProperty('notice')) {
      f7.notification
        .create({
          title: '배낭버스',
          titleRightText: '지금',
          text: f7route.query.notice,
          closeTimeout: 1500,
        })
        .open();
    }
  }, []);

  return (
    <Page
      name="home"
      className="home-page bg-gray"
      ptr
      ptrMousewheel
      onPtrRefresh={onRefresh}
      onPageAfterIn={() => {
        setMainViewHash('in');
      }}
      onPageAfterOut={() => {
        setMainViewHash('out');
      }}
    >
      <Navbar noHairline noShadow>
        <NavLeft>
          <Link panelOpen="left">
            <img src={menu} alt="" width={20} />
          </Link>
        </NavLeft>
        <NavTitle>
          <img src={logo} alt="" width={65} />
        </NavTitle>
        <NavRight>
          <Link href="/notifications">
            <img src={bell} alt="" width={20} />
          </Link>
        </NavRight>
      </Navbar>
      <div className="relative">
        <HomeBanner f7router={f7router} type="top" />
        <Block className="p-8 home-search-btn">
          <Button
            className="home-button"
            onClick={() => {
              if (window.isDesktop) {
                window.location.href = '/search';
              } else {
                f7.tab.show('#view-search', true);
              }
            }}
          >
            <p className="tracking-wider">어디로 가실 건가요?</p>
            <p style={{ position: 'absolute', right: '30px' }}>
              <img src={searchIcon} width="20" alt="" />
            </p>
          </Button>
        </Block>
      </div>
      <div className={`flex flex-col justify-around ${drivers ? '' : 'h-full'}`}>
        <Block className="pt-4">
          {drivers ? (
            <>
              <BlockTitle className="text-left text-xl font-bold pb-4 pl-5">내 주변 추천 기사님들</BlockTitle>

              <div className="grid gap-y-4 grid-cols-1 px-4 mb-10">
                {drivers.length > 0 ? (
                  drivers.map((d, idx) => <DriverListItem driver={d} key={`driver-${d?.id}`} withPrice={false} />)
                ) : (
                  <div className="h-40 flex justify-center items-center text-base">주변에 등록된 기사가 없습니다.</div>
                )}
              </div>
            </>
          ) : (
            <div className="sm:mt-16">
              <div className="flex flex-col items-center">
                <GoLocation size="50" color="#0064ff" />
                <p className="mt-6 text-lg text-primary">{errorText}</p>
              </div>
            </div>
          )}
        </Block>
        <HomeBanner f7router={f7router} type="home" />
      </div>
      <div style={{ paddingTop: '40px' }}>
        <Footer />
      </div>
    </Page>
  );
};
export default HomePage;
