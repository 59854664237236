import { Navbar, NavLeft, NavTitle, Link, NavRight } from 'framework7-react';
import React from 'react';
import { IoNotificationsOutline } from 'react-icons/io5';

const DefaultNavbar = ({ title }) => (
  <Navbar>
    <NavLeft>
      <Link icon="menu_bars" panelOpen="left" />
    </NavLeft>
    <NavTitle>{title}</NavTitle>
    <NavRight>
      <Link icon="noti_bell" href="/notifications" />
    </NavRight>
  </Navbar>
);

export default DefaultNavbar;
